import { PanelKeys, PanelLogoIcon } from '@/types';

import pluginJson from './plugin.json';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export enum ROUTES {
  Traces = 'traces',
  Logs = 'logs',
  Metrics = 'metrics',
  Overview = 'overview',
}

const pluginId = 'grafana-costmanagementui-app';

export const panels: { free: { [k in PanelKeys]: string }; paid: { [k in PanelKeys]: string } } = {
  free: {
    activeSeriesPerUsageGroup: 'Active series per usage group',
    billableLogsUsage: 'Total Logs Usage',
    billableSeries: 'Total Metrics Series',
    queryUsageRatio: 'Query Usage Ratio',
    billableTracesUsage: 'Total Billable Traces Usage',
    totalIngestedTracesByDay: 'Total Ingested Traces by day',
  },
  paid: {
    billableTracesUsage: 'Total Billable Traces Usage',
    activeSeriesPerUsageGroup: 'Active series per usage group',
    billableLogsUsage: 'Total Billable Logs Usage',
    billableSeries: 'Total Billable Metrics Series',
    queryUsageRatio: 'Query Usage Ratio',
    totalIngestedTracesByDay: 'Total Ingested Traces by day',
  },
};

export const phrases = {
  attribute: 'Which teams are responsible for usage?',
  inspect: 'What is my monthly usage, burn rate, and estimated bill?',
  monitoring: 'How do I prevent unexpected overages?',
  optimize: {
    general: 'How can I reduce my bill?',
    metrics: 'How can I reduce my billable time series?',
    logs: 'How can I reduce my billable logs?',
    traces: 'How can I reduce my billable traces?',
  },
};

export const paths = {
  appRoot: `/a/${pluginId}`,
  pluginPublic: `/public/plugins/${pluginId}`,
  adaptiveMetrics: '/a/grafana-adaptive-metrics-app',
  alertingList: '/alerting/list',
  cardinalityDashboard: '/d/cardinality-management',
  logVolumeExplorer: '/a/grafana-logvolumeexplorer-app',
  adaptiveLogs: '/a/grafana-adaptivelogs-app',
  adaptiveTraces: '/a/grafana-adaptivetraces-app',
  attributions: '/a/grafana-attributions-app',
} as const;

export const externalLinks = {
  billingAlerts:
    'https://grafana.com/docs/grafana-cloud/account-management/billing-and-usage/#billing-alerts-on-metrics-consumption',
  costAttributionReport:
    'https://grafana.com/docs/grafana-cloud/cost-management-and-billing/generate-cost-attribution-reports',
  dropLogLinesAgent: 'https://grafana.com/docs/grafana-cloud/cost-management-and-billing/reduce-costs/logs-costs',
  tracesReduceCosts: 'https://grafana.com/docs/grafana-cloud/cost-management-and-billing/reduce-costs/traces-costs/',
  grafanaSignin: 'https://grafana.com/auth/sign-in',
  grafanaInvoices: 'https://grafana.com/profile/org/invoices',
  logDataRejection: 'https://grafana.com/docs/grafana-cloud/monitor-infrastructure/logs/troubleshoot/',
  setupUsageAlerts: 'https://grafana.com/docs/grafana-cloud/cost-management-and-billing/set-up-usage-alerts/',
};

export const Icons: { [key in PanelLogoIcon]: string } = {
  attribution: `${paths.pluginPublic}/img/attribution.svg`,
  inspect: `${paths.pluginPublic}/img/inspect.svg`,
  monitor: `${paths.pluginPublic}/img/monitor.svg`,
  optimize: `${paths.pluginPublic}/img/optimize.svg`,
} as const;
